import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Main} from './components/Main';
import {Viewable} from './components/Viewable';
import './App.css';


class App extends Component {

  render() {
    return (
      <Router>
        <Switch> {/* renders first route that matches url */}
          <Route exact path="/" render={(props) => (
            <h1 class="App">
              <br /><br />
              <Main/>
            </h1>
          )} />

          <Route exact path="/show" component={Viewable}/>

        </Switch>
      </Router>
    );
  }
}

export default App;