import React, {Component} from 'react';
import Chart from "react-google-charts";

export class Viewable extends Component {

  // render line chart
  renderLineChart(chartData, length) {
    return (
      <div>

        <div className="center">
          <Chart
              width={'1000px'}
              height={'600px'}
              chartType="ScatterChart"
              loader={<div>Loading Chart</div>}
              data={chartData}
              options={{
                title: 'Sentiment Chart',
                hAxis: { title: 'Time Segment', titleTextStyle: { color: '#333' }, maxValue: length },
                vAxis: { minValue: 0, maxValue: 1 },
                legend: 'none',
                colors: ['#007bff'],
                // lineWidth: 25
                trendlines: {
                  0: {
                    type: 'polynomial',
                    color: 'green',
                    lineWidth: 10,
                    opacity: 0.2,
                  },
                }
              }}
            />
        </div>
      </div>
		);
  }

  renderStackedChart(chartData, length, tdoData) {

    let chartTitle = "Time Segment";
    if (tdoData.length > 0) {
      chartTitle += "\n(click on each label for more info)";
    }

    return (
      <div className="center">
        <Chart
          width={'100%'}
          height={'600px'}
          chartType="ColumnChart"
          loader={<div>Loading Chart</div>}
          data={chartData}
          options={{
            title: 'Sentiment Chart',
            isStacked: 'percent',
            legend: { position: 'top', maxLines: 3 },
            hAxis: { title: chartTitle, maxValue: length },
            vAxis: { minValue: 0, maxValue: 1 },
            //colors: ['#4CBB17', '#E60000', '#FF7800']  // positive, negative, neutral
            colors: ['#35B32E', '#EB5358', '#FFB42F'],
          }}
          chartEvents={[
            {
              eventName: "ready",
              callback: ({ chartWrapper, google }) => {
                var handler = function(e) {
                  //console.log("e: "+JSON.stringify(e));
                  var parts = e.targetID.split("#");
                  //console.log("parts: "+parts);
                  if (parts.indexOf("label") >= 0 || parts.indexOf("bar") >= 0) {

                    let idx = -1;
                    // map to the correct segment
                    if (parts.indexOf("label") >= 0) {
                      idx = parts[parts.indexOf("label") + 1];
                    } else if (parts.indexOf("bar") >= 0) {
                      idx = parts[parts.indexOf("bar") + 2];
                    }

                    idx = parseInt(idx);

                    //console.log("tdoData: " +tdoData);
                    //console.log("tdoData[idx]: " +tdoData[idx]);

                    // trim quotes
                    let tdo = tdoData[idx];
                    if (tdo.length > 2 && tdo.charAt(0)==='"' && tdo.charAt(tdo.length-1)==='"') {
                      tdo = tdo.substring(1,tdo.length-1);
                    } 
                    if (tdo) {
                      window.open("https://cms.veritone.com/#/media-details/"+tdo, "_blank");
                    }
                  }
                };
                google.visualization.events.addListener(
                  chartWrapper.getChart(),
                  "click",
                  handler
                );
              }
            }
          ]}
        />
      </div>
    );
  }

  trimArrayInParams(params) {
    if (params.length > 2 && params.charAt(0)==="[" && params.charAt(params.length-1)==="]") {
      return params.substring(1,params.length-1);
    } else {
      return params;
    }
  }


	render() {

    // construct data to display
    const search = this.props.location.search;

    // sample url: /show?y=0.2,0.3,0.4, /show?y=[0.2,0.3,0.4]

    let yParams = new URLSearchParams(search).get('y');
  
    if (yParams && yParams.length > 0) {
      // display line chart
      yParams = this.trimArrayInParams(yParams);

      //console.log("params: " + params);

      const yParamsArray = yParams.split(',');

      let lineChartData = [['Time','Sentiment']];
      let x = 0;
      yParamsArray.forEach(function(y) {
        x++;
        lineChartData.push([parseInt(x),parseFloat(y)]);
      });

      console.log("lineChartData: " + lineChartData);

      return this.renderLineChart(lineChartData, x+1);
      
    }

    // sample url: /show?pos=[0.96,0,0.72]&neg=[0.02,1,0.16]

    let posParams = new URLSearchParams(search).get('pos');
    let negParams = new URLSearchParams(search).get('neg');
    let tdoParams = new URLSearchParams(search).get('tdo');

    if (posParams && posParams.length > 0 
        && negParams && negParams.length > 0) {

      posParams = this.trimArrayInParams(posParams);
      negParams = this.trimArrayInParams(negParams);

      let posParamsArray = posParams.split(',');
      let negParamsArray = negParams.split(',');

      let tdoData = []; // link to TDO data
      if (tdoParams && tdoParams.length > 0) {
        tdoParams = this.trimArrayInParams(tdoParams);
        tdoData = tdoParams.split(',');
      }

      let colChartData = [['Time','Positive', 'Negative', 'Neutral']];

      const minLength = Math.min(posParamsArray.length, negParamsArray.length);
      for (let x=0; x<minLength; x++) {

        let positive = parseFloat(posParamsArray[x]);
        let negative = parseFloat(negParamsArray[x]);
        let neutral = 1-(positive+negative);
        if (neutral<0) {
          neutral = 0;
        }
        
        colChartData.push([(x+1).toString(), positive, negative, neutral]);

      }
      console.log("colChartData: " + colChartData);

      return this.renderStackedChart(colChartData, minLength, tdoData);


    }


    return (
      <div>No parameters found. Valid parameters are required to show charts.</div>

    );


	}
}

